import {Route, Redirect, Switch} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'
import { AuthRequired } from "../../GriPages/authRequired";

const Login = lazy(() => import('../../GriPages/Login'));
const Logout = lazy(() => import('../../GriPages/Logout'));
const output = lazy(() => import('../../GriPages/Output'));
const input = lazy(() => import('../../GriPages/Input'));
const securities = lazy(() => import('../../GriPages/Securities'));
const security_groups = lazy(() => import('../../GriPages/SecurityGroup'));
const ResetLink = lazy(() => import('../../GriPages/PasswordRecovery/ResetLink'));
const UpdatePassword = lazy(() => import('../../GriPages/PasswordRecovery/UpdatePassword'));

const AppMain = () => {

    return (
        <Fragment>

            {/* Rivkin */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Loading...
                        </h6>
                    </div>
                </div>
            }>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    {/* <Route path="/inputs" component={input}/> */}



                    {/* <Route exact path="/logout" render={() => (
                        <AuthRequired redirectTo='/logout' orRender={<Route path="/logout" component={Logout}/>}
                    />)}/> */}

                    <Route exact path="/inputs" render={() => (
                        <AuthRequired redirectTo='/inputs' orRender={<Route path="/inputs" component={input}/>}
                    />)}/>

                    {/* <Route path="/securities" component={securities}/>   */}

                    <Route exact path="/securities" render={() => (
                        <AuthRequired redirectTo='/securities' orRender={<Route path="/securities" component={securities}/>}
                    />)}/>
                    
                    <Route path="/security_groups" component={security_groups}/>

                    <Route exact path="/security_groups" render={() => (
                        <AuthRequired redirectTo='/security_groups' orRender={<Route path="/security_groups" component={security_groups}/>}
                    />)}/>
                    

                    {/* <Route path="/outputs" component={output}/>
                    <Route path="/outputs/:input_id" component={output}/> */}



                    {/* <Route exact path="/outputs" render={() => (
                        <AuthRequired redirectTo='/outputs' orRender={<Route path="/outputs" component={output}/>}
                    />)}/> */}

                    <Route path="/outputs/:input_id" render={() => (
                        <AuthRequired redirectTo='/outputs/:input_id' orRender={<Route path="/outputs/:input_id" component={output}/>}
                    />)}/>
        

                    <Route path="/reset-password-link" component={ResetLink}/>
                    <Route path="/password/reset/:user_id/:user_token" component={UpdatePassword}/>


                </Switch>
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/inputs"/>
            )}/>

        </Fragment>
    )
};

export default AppMain;
