import Configuration from './configuration';

class GriApiClient {
    constructor() {
        this.config = new Configuration();
    }

    async request(method, action, body, mustAuth) {
        let token = localStorage.getItem('token');
        if (mustAuth && token == null)
            return null;

        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        if (mustAuth)
            headers.Authorization = 'Bearer ' + token;

        return fetch(this.config.RIVKIN_API_URL + action, {
                method: method,
                headers: headers,
                body: body == null ? null : JSON.stringify(body)
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(response.status)
                }
                return response.json();
            });
    }

    async requestFile(method, action, body, mustAuth, headers = {}) {
        let token = localStorage.getItem('token');
        if (mustAuth && token == null)
            return null;

        if (mustAuth)
            headers.Authorization = 'Bearer ' + token;

        return fetch(this.config.RIVKIN_API_URL + action, {
                method: method,
                headers: headers,
                body: body == null ? null : body
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(response.status)
                }
                return response.json();
            });
    }

    // async requestCsv(method, action, body, mustAuth) {
    //     let token = localStorage.getItem('token');
    //     if (mustAuth && token == null)
    //         return null;

    //     let headers = { "Content-Type": "text/csv;encoding:utf-8" };
    //     if (mustAuth)
    //         headers.Authorization = 'Bearer ' + token;

    //     return fetch(this.config.RIVKIN_API_URL + action, {
    //             method: method,
    //             headers: headers,
    //             body: body == null ? null : JSON.stringify(body)
    //     }).then (res => res.text());
    // }

    // async requestPdf(method, action, body, mustAuth) {
    //     let token = localStorage.getItem('token');
    //     if (mustAuth && token == null)
    //         return null;

    //     let headers = { "Content-Type": "application/pdf;encoding:utf-8" };
    //     if (mustAuth)
    //         headers.Authorization = 'Bearer ' + token;

    //     return fetch(this.config.RIVKIN_API_URL + action, {
    //             method: method,
    //             headers: headers,
    //             body: body == null ? null : (body)
    //     }).then (res => res.text());
    // }

    // async getPdf(action, mustAuth = true) {
    //     return this.requestPdf('GET', action, null, mustAuth);
    // }

    // async getCsv(action, mustAuth = true) {
    //     return this.requestCsv('GET', action, null, mustAuth);
    // }

    // async postCsv(action, body, mustAuth = true) {
    //     return this.requestCsv('POST', action, body, mustAuth);
    // }

    async get(action, mustAuth = true) {
        return this.request('GET', action, null, mustAuth);
    }

    async post(action, body, mustAuth = true) {
        return this.request('POST', action, body, mustAuth);
    }

    async uploadFile(action, body, mustAuth = true) {
        return this.requestFile('POST', action, body, mustAuth);
    }

    async put(action, body, mustAuth = true) {
        return this.request('PUT', action, body, mustAuth);
    }

    async delete(action, body, mustAuth = true) {
        return this.request('DELETE', action, body, mustAuth);
    }
}

export default GriApiClient;